'use client'

import i18n from '@tebuto/i18n/client'
import { I18nextProvider } from 'react-i18next'

interface TranslationProviderProps {
    defaultNamespace?: 'client' | 'app' | 'components' | 'common'
    children: React.ReactNode
}

export default function TranslationProvider({ defaultNamespace, children }: TranslationProviderProps): JSX.Element {
    return (
        <I18nextProvider i18n={i18n} defaultNS={defaultNamespace}>
            {children}
        </I18nextProvider>
    )
}
