'use client'

import { MapIcon, DocumentTextIcon as OutlineDocumentTextIcon } from '@heroicons/react/24/outline'
import { ArchiveBoxIcon, DocumentTextIcon } from '@heroicons/react/24/solid'
import { useLoginStatus } from '@tebuto/functions/hooks'
import { useComponentTranslation } from '@tebuto/functions/translation.components'
import { features } from '@tebuto/translations'
import { Building2, MailQuestionIcon, NotebookPenIcon, UsersIcon } from 'lucide-react'
import { useMemo } from 'react'
import { Menu, MenuEntry } from '../Menu'

export const Header = () => {
    const { t } = useComponentTranslation()
    const isLoggedIn = useLoginStatus()

    const { leftItems, rightItems } = useMemo(() => {
        const leftItems: MenuEntry[] = [
            { name: t('layout.landing.menu.home'), href: '/' },
            {
                name: t('layout.landing.menu.usage'),
                href: '#',
                mobileHref: '/features',
                mobileBehaviour: 'titleOnly',
                subMenu: {
                    layout: '2rows',
                    items: features.map(feature => {
                        return { name: feature.title, icon: feature.icon, href: feature.href, description: feature.subtitle }
                    }),
                    cta: [
                        { name: 'Alle Funktionen', href: '/features', icon: ArchiveBoxIcon },
                        { name: 'Dokumentation', href: '/dokumentation', icon: DocumentTextIcon }
                    ]
                }
            },
            {
                name: 'Ressourcen',
                href: '#',
                mobileBehaviour: 'flat',
                subMenu: {
                    items: [
                        { name: t('layout.landing.menu.roadmap'), href: '/roadmap', icon: MapIcon, description: 'Deine Vorschläge für eine bessere Software' },
                        { name: t('layout.landing.menu.blog'), href: '/blog', icon: NotebookPenIcon, description: 'Blog-Beiträge aus dem psychosozialen Bereich' },
                        { name: 'Dokumentation', href: '/dokumentation', icon: OutlineDocumentTextIcon, description: 'Alles, was du wissen musst' },
                        { name: 'Kontakt', href: '/kontakt', icon: MailQuestionIcon, description: 'Fragen oder Anregungen? Schreib uns!' }
                    ]
                }
            },
            { name: t('layout.landing.menu.price'), href: '/preis' },
            { name: t('layout.landing.menu.aboutUs'), href: '/ueber-uns' }
        ]

        const rightItems: MenuEntry[] = []

        if (isLoggedIn) {
            rightItems.push({ name: t('layout.landing.toApp'), href: process.env.NEXT_PUBLIC_APP_BASE_URL as string, isAbsolute: true, highlight: true })
        } else {
            rightItems.push({
                name: t('layout.landing.menu.login'),
                href: '#',
                id: 'login',
                mobileBehaviour: 'default',
                subMenu: {
                    items: [
                        {
                            name: t('layout.landing.menu.appointmentProviderLoginTitle'),
                            href: process.env.NEXT_PUBLIC_THERAPISTS_AUTHORIZE_URL as string,
                            icon: Building2,
                            description: t('layout.landing.menu.appointmentProviderLoginDescription'),
                            isAbsolute: true
                        },
                        {
                            name: t('layout.landing.menu.clientLoginTitle'),
                            href: process.env.NEXT_PUBLIC_CLIENTS_AUTHORIZE_URL as string,
                            icon: UsersIcon,
                            description: t('layout.landing.menu.clientLoginDescription'),
                            isAbsolute: true
                        }
                    ]
                }
            })
            rightItems.push({ name: t('layout.landing.menu.register'), href: '/registrieren', highlight: true })
        }

        return { leftItems, rightItems }
    }, [isLoggedIn, features, t])

    const additionalMobileItems = [{ name: 'Dokumentation', href: '/dokumentation' }]

    return <Menu id="top" leftItems={leftItems} rightItems={rightItems} baseUrl={`${process.env.NEXT_PUBLIC_LANDING_BASE_URL}`} additionalMobileItems={additionalMobileItems} />
}
