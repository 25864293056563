interface ContainerProps {
    children: React.ReactNode
    className?: string
    ignorePaddingX?: boolean
    id?: string
    fullWidth?: boolean
    narrow?: boolean
    style?: React.CSSProperties
}

export default function Container({ style, fullWidth, children, className = '', id, ignorePaddingX, narrow }: ContainerProps) {
    const fullWidthStyle = 'w-full'
    const boxedWidthStyle = 'max-w-8xl'
    const narrowWidthStyle = 'max-w-5xl'
    const fullWidthStyles = fullWidth ? fullWidthStyle : narrow ? narrowWidthStyle : boxedWidthStyle

    const defaultPaddingStyle = 'px-6 2xl:px-0'
    const noPaddingStyle = ''
    const ignorePaddingXStyles = ignorePaddingX ? noPaddingStyle : defaultPaddingStyle

    return (
        <section id={id} style={style} className={`${fullWidthStyles} mx-auto ${ignorePaddingXStyles} ${className}`}>
            {children}
        </section>
    )
}
