'use client'
import i18n from 'i18next'
import resourcesToBackend from 'i18next-resources-to-backend'
import { initReactI18next } from 'react-i18next'

const runsOnServerSide = typeof window === 'undefined'

i18n.use(initReactI18next)
    .use(resourcesToBackend((language: string, namespace: string) => import(`../../public/locales/${language}/${namespace}.json`)))
    .init({
        lng: 'de',
        fallbackLng: 'de',
        preload: runsOnServerSide ? ['de'] : [],
        load: 'languageOnly'
    })

export default i18n
