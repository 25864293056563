import { CalendarDateRangeIcon, CalendarIcon, ChatBubbleLeftRightIcon, ComputerDesktopIcon, LockClosedIcon, ScaleIcon, WifiIcon } from '@heroicons/react/24/outline'
import { BlocksIcon } from 'lucide-react'

interface Feature {
    title: string
    subtitle: string
    description: string
    screenshots: Image[]
    image: Image
    href: string
    icon: any
}

interface Image {
    src: string
    mobileSrc: string
}

const COMPONENT_TEXTS = {
    layout: {
        landing: {
            footerTitle: 'Footer',
            slogan: 'Online-Terminbuchung für Therapie, Beratung und Coaching auf Selbstzahlerbasis',
            toApp: 'Zur App',
            menu: {
                register: 'Kostenlos starten',
                createEntry: 'Eintrag erstellen',
                home: 'Startseite',
                aboutUs: 'Über uns',
                contact: 'Kontakt',
                documentation: 'Dokumentation',
                directory: 'Therapeuten-Verzeichnis',
                status: 'Status',
                roadmap: 'Roadmap',
                impress: 'Impressum',
                privacyDeclaration: 'Datenschutzerklärung',
                usage: 'Funktionen',
                price: 'Preis',
                blog: 'Blog',
                testimonials: 'Kundenstimmen',
                waitlist: 'Warteliste',
                faq: 'FAQ',
                features: {
                    title: 'Funktionen',
                    clientBooking: {
                        title: 'Privates Klientenportal',
                        subtitle: 'Weniger Stress, mehr Klientenzufriedenheit',
                        description: 'Biete deinen Klient:innen/Patient:innen Flexibilität und Komfort - ohne zusätzlichen Aufwand für dich.',
                        screenshots: [{ src: '/assets/screenshots/termine-buchen.webp', mobileSrc: '/assets/screenshots/termine-buchen-mobile.webp' }],
                        image: { src: '/assets/carousel/termine-buchen.webp', mobileSrc: '/assets/carousel/termine-buchen-mobile.webp' },
                        href: '/features/klientenportal',
                        icon: ComputerDesktopIcon
                    },
                    appointmentManagement: {
                        title: 'Termin-Management',
                        subtitle: 'Alle Termine, übersichtlich an einem Ort',
                        description: 'Behalte deine Termine stets im Blick und organisiere sie effizient.',
                        screenshots: [{ src: '/assets/screenshots/alle-termine-im-blick.webp', mobileSrc: '/assets/screenshots/alle-termine-im-blick-mobile.webp' }],
                        href: '/features/termin-management',
                        image: { src: '/assets/carousel/terminmanagement.webp', mobileSrc: '/assets/carousel/terminmanagement-mobile.webp' },
                        icon: CalendarDateRangeIcon
                    },
                    publicBooking: {
                        title: 'Terminbuchung-Plugin',
                        subtitle: 'Termine auf deiner eigenen Webseite anbieten',
                        description: 'Biete deine Termine auf deiner eigenen Webseite an. Einfach, schnell und ohne Programmierkenntnisse.',
                        screenshots: [{ src: '/assets/screenshots/terminbuchung-widget.webp', mobileSrc: '/assets/screenshots/terminbuchung-widget-mobile.webp' }],
                        image: { src: '/assets/carousel/termine-buchen.webp', mobileSrc: '/assets/carousel/termine-buchen-mobile.webp' },
                        href: '/features/terminbuchung-plugin',
                        icon: BlocksIcon
                    },
                    onlineMeeting: {
                        title: 'Online-Meetings',
                        subtitle: 'Unterstützung bei virtuellen Terminen',
                        description: 'Führe deine Sitzungen bei Bedarf online durch - sicher, einfach und flexibel.',
                        screenshots: [{ src: '/assets/screenshots/online-meetings.webp', mobileSrc: '/assets/screenshots/online-meetings-mobile.webp' }],
                        image: { src: '/assets/carousel/online-meetings.webp', mobileSrc: '/assets/carousel/online-meetings-mobile.webp' },
                        href: '/features/online-meetings',
                        icon: WifiIcon
                    },
                    cancellationFee: {
                        title: 'Ausfallhonorare',
                        subtitle: 'Weniger Terminausfälle, mehr Fairness',
                        description: 'Ein Ausfallhonorar schützt deine Zeit, unterstreicht den Wert jeder Sitzung und bleibt flexibel: Du entscheidest, wann es angewendet wird.',
                        screenshots: [{ src: '/assets/screenshots/ausfallhonorar.webp', mobileSrc: '/assets/screenshots/ausfallhonorar-mobile.webp' }],
                        image: { src: '/assets/carousel/ausfallhonorar.webp', mobileSrc: '/assets/carousel/ausfallhonorar-mobile.webp' },
                        href: '/features/ausfallhonorar',
                        icon: ScaleIcon
                    },
                    calendarIntegration: {
                        title: 'Kalender-Integration',
                        subtitle: 'Doppelbuchungen und Engpässe vermeiden',
                        description: 'Optimiere deine Terminplanung durch nahtlose Synchronisation mit bestehenden Kalendern und vermeide Doppelbuchungen.',
                        screenshots: [{ src: '/assets/screenshots/kalender.webp', mobileSrc: '/assets/screenshots/kalender-mobile.webp' }],
                        image: { src: '/assets/carousel/kalender.webp', mobileSrc: '/assets/carousel/kalender-mobile.webp' },
                        href: '/features/kalender-integration',
                        icon: CalendarIcon
                    },
                    individualNotifications: {
                        title: 'Automatisierte Kommunikation',
                        subtitle: 'Zeit sparen ohne Qualitätsverlust',
                        description: 'Optimiere deine Praxisabläufe mit automatisierten Benachrichtigungen und Erinnerungen, ohne dabei an Kontrolle oder Qualität zu verlieren.',
                        screenshots: [
                            {
                                src: '/assets/screenshots/individuelle-nachrichten-und-erinnerungen.webp',
                                mobileSrc: '/assets/screenshots/individuelle-nachrichten-und-erinnerungen-mobile.webp'
                            }
                        ],
                        image: { src: '/assets/carousel/kommunikation.webp', mobileSrc: '/assets/carousel/kommunikation-mobile.webp' },
                        href: '/features/automatisierte-kommunikation',
                        icon: ChatBubbleLeftRightIcon
                    },
                    dataProtection: {
                        title: 'Datenschutz und DSGVO',
                        subtitle: 'Sensitive Daten schützen',
                        description: 'Daten deiner Klienten und Patienten sollten mit höchster Sorgfalt behandelt werden und nicht in falsche Hände geraten.',
                        screenshots: [],
                        image: { src: '/assets/carousel/datenschutz.webp', mobileSrc: '/assets/carousel/datenschutz-mobile.webp' },
                        href: '/features/datenschutz-und-dsgvo',
                        icon: LockClosedIcon
                    }
                },
                support: 'Support',
                links: 'Wichtige Links',
                login: 'Anmelden',
                law: 'Rechtliches',
                avv: 'Auftragsverarbeitungsvertrag',
                cookieSettings: 'Cookie-Einstellungen',
                terms: 'AGB'
            },
            social: {
                linkedIn: 'LinkedIn',
                linkedInTitle: 'Tebuto auf LinkedIn',
                instagram: 'Instagram',
                instagramTitle: 'Tebuto auf Instagram',
                gitHub: 'GitHub',
                gitHubTitle: 'Tebuto auf GitHub',
                youtube: 'YouTube',
                youtubeTitle: 'Tebuto auf YouTube'
            }
        }
    }
}

type FeatureKeys = keyof typeof COMPONENT_TEXTS.layout.landing.menu.features
const { title, ...rest } = COMPONENT_TEXTS.layout.landing.menu.features
export const features: Feature[] = (Object.keys(rest) as FeatureKeys[]).filter(key => key !== 'title').map(key => COMPONENT_TEXTS.layout.landing.menu.features[key])

export default COMPONENT_TEXTS
